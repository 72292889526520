import React from 'react';
import {
    Flex,
    Box,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import { PhoneIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

export default function ListingCard(props) {
    return (
        <Box w={{ base: '100%', md: '50%', lg: '33.33%'}} p="4"
            as={motion.div}
            layout 
            initial={{opacity: 0}}
            animate={{ opacity: 1, transition: { duration: 0.75 }}}
            exit={{ opacity: 0}}
        >
            <Flex h="100%" justifyContent="space-between" flexDirection="column" as="article" boxShadow="md" borderRadius="sm" p="4">
                <Box mb="2">
                    <Heading as="h5" variant="h5" color="brand.500" fontWeight="700">
                        {props.listingTitle}
                    </Heading>
                </Box>
                <Box>
                    <Text mb="1">
                        {props.listingAddress}
                    </Text>
                </Box>
                <Flex w="100%" justifyContent="space-between">
                    <Box mb="1">
                        <Link href={`tel:${props.listingPhone}`} color="brand.400" fontWeight="400" _hover={{color: 'snowscapes.500', textDecoration: 'none'}}>
                            <PhoneIcon w="3" h="3" />{' '}{props.listingPhone}
                        </Link>
                    </Box>
                    <Box>
                        <Link lineHeight="0" href={props.listingURL} target="_blank" color="brand.400"  fontWeight="400" rel="noopener noreferrer" _hover={{color: 'snowscapes.500', textDecoration: 'none'}}>Website{' '}<ExternalLinkIcon /></Link>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    )
}