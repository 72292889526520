import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {
    Flex,
    Box,
    Container,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import ListingCard from './ListingCard'

export default function BusinessListings() {

  const [listing, setListing] = useState([]);
  const [filtered, setFiltered] = useState([]);

    const data = useStaticQuery(graphql`
    query {
      allPrismicBusinessListings(sort: {fields: data___listing___business_name, order: ASC}) {
        edges {
          node {
            uid
            data {
              listing {
                business_url {
                  url
                }
                business_phone
                business_name
                business_address
              }
              category
            }
          }
        }
      }
    }
  `)

  const doc = data.allPrismicBusinessListings.edges;

  const listingData = doc.map(d => {
    return d.node.data.listing.map(item => {
      return item
    });
  });

  // Create an array of just the listing categories
  const listings = doc.map(category => {
    return (
      {
        cat: category.node.data.category
      }
    )
  })

  //console.log(listings)

  let sortedListings = 
  listings.sort(function (a, b) {
    if (a.cat < b.cat) {
      return -1;
    }
    if (a.cat > b.cat) {
      return 1;
    }
    return 0;
  });

 // console.log(sortedListings)

  const handleChange = e => {
    if (e.target.checked) {
      setListing([e.target.value]);
    } else {
      setListing(listing.filter(id => id !== e.target.value))
    }
  }

  // Filter listings
  useEffect(() => {
    if (listing.length === 0) {
      setFiltered(doc);
    } else {
      setFiltered(
        doc.filter((item) => 
          listing.some((category) => item.node.data.category.includes(category)))
      )
    }
  }, [listing, doc]);

  //console.log(listings)
 
  return (
        <Container maxW="container.xl">
          <Heading as="h2" variant="h3" textAlign="center" mb="2">Filter businesses by category:</Heading>
            <Flex w="100%" justifyContent="center" flexWrap="wrap" borderBottom="1px solid #ADD4EA" mb="8">
            <Box p="2" display="inline-block" textAlign="center" mr="4">
                          <label class="check" htmlFor="all">
                            <input 
                              id="all"
                              name="category"
                              type="radio"
                              onChange={handleChange}
                              value={listings.cat}
                              checked={listings.cat}
                              defaultChecked
                            />
                            <span>All</span>
                          </label>
                        </Box>
                {listings.map((listings, index) => {
                    return (
                        <Box key={index} p="2" display="inline-block" textAlign="center" mr="4">
                          <label class="check" htmlFor={listings.cat}>
                            <input 
                              id={listings.cat}
                              name="category"
                              type="radio"
                              onChange={handleChange}
                              value={listings.cat}
                              checked={listing.includes(listings.cat)}
                            />
                            <span>{listings.cat}</span>
                          </label>
                        </Box>
                    )
                })}
            </Flex>
                <Flex w="100%" flexWrap="wrap">
                    {filtered.map((item) => {
                      //console.log(item)
                      return item.node.data.listing.map((thing, idx) => (
                          <ListingCard 
                              key={idx}
                              listingTitle={thing.business_name}
                              listingAddress={thing.business_address}
                              listingPhone={thing.business_phone}
                              listingURL={thing.business_url.url}
                          />
                      ))
                    } 
                  )}
                </Flex>
        </Container>
        
    )
}